import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Title from "../components/Title";
import Form from "../components/Form"
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const Contact = ({pageContext}) => {
  const {
    breadcrumb: {crumbs},
  } = pageContext

  const data = useStaticQuery(graphql`{
      webform: webformWebform(drupal_internal__id: { eq: "contact" }) {
          title
          drupal_internal__id
          elements {
              name
              type
              attributes {
                  name
                  value
              }
          }
      }
  }`)

  return (
    <Layout>
      <Metas title={data.webform.title}/>
      <section className="section page-breadcrumb is-small has-background-light">
        <div className="container">
            <CustomBreadcrumb crumbs={crumbs}/>
        </div>
      </section>
      <section className="section page-content">
        <div className="container">
          <Title
            title={data.webform.title}
            subtitle="Pour toute demande d'informations, n'hésitez pas à nous adresser un message."
            className="has-text-centered"
          />
          <Form webform={data.webform}/>
        </div>
      </section>
    </Layout>
  )
}

export default Contact